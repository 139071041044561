<script setup>
import {useRouter} from "vue-router";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import {storeToRefs} from "pinia";
import Map from "@/components/coffee/Map.vue";
import HeroCounter from "@/components/coffee/HeroCounter.vue";
import CoffeeGraphic from "@/assets/image/coffee/graphic-coffee.png"
import NumberTitle from "@/views/coffee/NumberTitle.vue";
import CheckTitle from "@/views/coffee/CheckTitle.vue";
import Exclamation from "@/components/Exclamation.vue";
import QNABlock from "@/components/coffee/QNABlock.vue";
import {computed, onBeforeUnmount, onMounted} from "vue";
import {formatDate} from "../../utils/utils.js";
import {dateParser, timeParser} from "../../utils/coffee.js";

const router = useRouter()
const coffeeStore = useCoffeeStore()

const {cafe} = storeToRefs(coffeeStore)

onMounted(async () => {
  document.getElementById('coffeeMainLayout').classList.add('pt-0')
  document.getElementById('coffeeMainLayout').classList.remove('pt-[60px]')
  document.getElementById('coffeeSubLayout').classList.remove('px-5')
})

onBeforeUnmount(() => {
  document.getElementById('coffeeMainLayout').classList.add('pt-[60px]')
  document.getElementById('coffeeMainLayout').classList.remove('pt-0')
  document.getElementById('coffeeSubLayout').classList.add('px-5')
})

const region = computed(() => {
  return cafe.value?.data?.region
})
</script>



<template>
  <div class="main-box">
    <!-- 상단 히어로 영역 시작 -->
    <div class="hero-box">
      <div class="graphic">
        <img :src="CoffeeGraphic" alt="커피 이미지" style="height: 154px"/>
      </div>
      <!-- 타이틀 영역 시작 -->
      <div class="cf-head-2 pt-[92px] mb-8 text-white">
        <template v-if="!region">
          <!-- 등록이 없는 경우-->
          앱으로 간편하게<br>수거 신청하면 끝!
        </template>
        <template v-else-if="region?.tomorrow_collection?.can_request === true">
          <!-- 내일이 수거일일 경우-->
          <template v-if="region?.next_collection?.request_count < region?.next_collection?.capacity">
<!--            앱으로 간편하게<br>수거 신청하면 끝!-->
            오늘 {{timeParser(region.dispose_time)}}까지<br>수거 신청 가능합니다.
          </template>
          <template v-else>
            오늘<br>수거 신청이<br>마감되었습니다.
          </template>
        </template>
        <template v-else>
          <!-- 내일이 수거일이 아닐 경우-->
          <template v-if="Array.isArray(region?.tomorrow_collection?.restricted_reason_message)">
            <div v-for="msg in region?.tomorrow_collection?.restricted_reason_message">
              {{ msg }}
            </div>
          </template>
          <template v-else>
            {{ region?.tomorrow_collection?.restricted_reason_message }}
          </template>
        </template>
      </div>
      <!-- 타이틀 영역 끝 -->
      <!-- 카운터 영역 시작-->
      <div class="w-full">
        <template v-if="!region">
          <!-- 등록이 없는 경우-->
        </template>
        <template v-else-if="region?.next_collection?.is_tomorrow">
          <div class="flex gap-x-5">
            <!-- 내일이 수거일일 경우-->
            <template v-if="region?.next_collection?.request_count < region?.next_collection?.capacity">
              <!-- 민원 등의 사유로 초과 신청을 받더라도 화면단에선 capacity를 최대값으로 표시-->
              <HeroCounter :count="Math.min(region?.next_collection?.request_count, region?.next_collection?.capacity)"
                           label="접수"/>
              <HeroCounter :count="region?.next_collection?.capacity" label="신청 가능 잔여 수"/>
            </template>
            <template v-else>
              <div class="cf-title-4 text-white">
                {{ dateParser(region?.next_collection?.can_request_date) }} 오전 0시부터 신청 가능합니다.
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="cf-title-4 text-white">
            {{ dateParser(region?.next_collection.can_request_date) }} 오전 0시부터 신청 가능합니다.
          </div>
        </template>
      </div>
      <!-- 카운터 영역 끝-->
    </div>
    <!-- 상단 히어로 영역 끝 -->
    <!-- 다음 수거일 표시 영역 시작-->
    <div class="next-collection-block flex gap-x-[4px] align-start justify-start" v-if="region">
      <div class="exclamation-mark-white mt-1">
        <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
              fill="white"/>
        </svg>
      </div>
      <div class="cf-title-4 text-white">
        다음 수거일 {{ dateParser(region?.next_collection.date) }}
      </div>
    </div>
    <!-- 다음 수거일 표시 영역 끝-->
    <div class="content-box">
      <div class="mb-9">
        <div class="cf-head-4 cf-text-gray-900 mb-1">커피박 수거 신청하기</div>
        <div class="cf-title-4 cf-text-gray-700 mb-5">커피박 버리지말고 무상 수거 신청하세요.</div>
        <router-link :to="{name: cafe?.type === 'cafe' ? 'coffee-request-collection' : 'coffee-request-register'}"
                     class="secondary-btn cf-head-6 mb-7 cf-text-primary w-full">
          {{ cafe?.type === 'cafe' ? '수거  신청' : '업체 등록' }}
        </router-link>
      </div>
      <div>
        <div class="cf-head-4 text-black mb-3">이용방법</div>
        <div class="mb-9">
          <NumberTitle class="mb-2">
            <template #number>1</template>
            <template #title>배출 업체 등록</template>
          </NumberTitle>
          <div class="cf-body-2 cf-text-gray-900">
            커피찌꺼기(커피박) 수거는 지구하다와 협약이 이루어진 지자체 지역 내 사업장을 대상으로 합니다. 업체 정보 등록 및 승인 완료 후 무상 수거 이용 가능합니다.
          </div>
        </div>
        <div class="mb-9">
          <NumberTitle class="mb-2">
            <template #number>2</template>
            <template #title>수거 가방에 커피찌꺼기(커피박) 담기</template>
          </NumberTitle>
          <div class="cf-body-2 cf-text-gray-900">
            커피박을 봉투 째 전용 수거 가방에 담아주세요. 전용 수거 가방을 받지 못하셨을 경우, 내용물이 보이는 투명 봉투에 담아주세요.
          </div>
        </div>
        <div class="mb-9">
          <div class="mb-2">
            <NumberTitle>
              <template #acc>
                <div class="absolute bottom-0 left-0 w-full h-[10px]"
                     style="background: var(--CF-Highlight, #B4FB39);"></div>
              </template>
              <template #number>!</template>
              <template #title>
                7월 중 전용 수거 가방을 드립니다.
              </template>
            </NumberTitle>
          </div>
          <div class="cf-body-2 cf-text-gray-900">
            <ul class="cf-ul">
              <li class="cf-body-2 cf-text-gray-900">
                <span class="cf-head-6">커피박 외에 다른 이물질</span>이 들어가지 않게 <span class="cf-head-6">주의</span>해주세요.
              </li>
              <li class="cf-body-2 cf-text-gray-900">
                <span class="cf-head-6">수거가방 또는 포장 봉투가 파손</span>되어 내용물이 쏟아진 경우 <span
                  class="cf-head-6">수거가 어려워 재신청이 필요</span>합니다.
              </li>
            </ul>
          </div>
        </div>
        <div class="mb-9">
          <NumberTitle class="mb-2">
            <template #number>3</template>
            <template #title>배출일 전날 오후 9시 전에 수거신청 하기</template>
          </NumberTitle>
          <div class="cf-body-2 cf-text-gray-900 mb-2">
            <ul class="cf-ul">
              <li class="cf-body-2 cf-text-gray-900">
                수거는 오전 9시 ~ 오후 9시 사이에 진행됩니다.
              </li>
              <li class="cf-body-2 cf-text-gray-900">
                수거 신청은 오전 12시 ~ 오후 9시까지 신청 가능합니다.
              </li>
            </ul>
          </div>
          <div class="acc-box">
            <div class="mb-5">
              <CheckTitle>
                <template #title>배출일 전날 오후 9시 전에 수거신청 하기</template>
                <template #body>다음날 오전 9시 ~ 오후 9시 사이 수거 예정</template>
              </CheckTitle>
            </div>
            <div>
              <CheckTitle>
                <template #title>오후 9시 1분 ~ 오후 11시 59분</template>
                <template #body>수거 신청 불가</template>
              </CheckTitle>
            </div>
          </div>
        </div>
        <div class="mb-9">
          <NumberTitle class="mb-2">
            <template #number>4</template>
            <template #title>배출 방법</template>
          </NumberTitle>
          <div class="cf-body-2 cf-text-gray-900 mb-1">
            수거일에 맞춰 등록한 배출 장소에 커피박을 배출해 주세요. (매장 내부, 매장 앞 등등)
          </div>
          <Exclamation>
            기사님이 방문해 약속된 장소에서 커피박 수거 후 전용 가방을 그 장소에 두고 옵니다. 잊지 말고 꼭 회수 부탁드립니다.
          </Exclamation>
        </div>
        <div class="mb-9">
          <NumberTitle class="mb-2">
            <template #number>5</template>
            <template #title>수거 완료</template>
          </NumberTitle>
          <div class="cf-body-2 cf-text-gray-900 mb-1">
            수거 완료시 알림톡(문자)이 발송됩니다.
          </div>
        </div>
        <div class="cf-head-4 text-black mb-3">이용지역</div>
        <div class="mb-9">
          <Map class="w-full"/>
        </div>
        <div class="cf-head-4 text-black mb-3">FAQ</div>
        <QNABlock>
          <template #q>수거 비용은 얼마인가요?</template>
          <template #a>지구하다는 지자체와의 협약을 통해 무상으로 수거를 해드리고 있습니다.</template>
        </QNABlock>
      </div>
    </div>
  </div>
</template>

<style scoped>


.main-box {
  width: 100%;
  overflow-y: auto;
}

.hero-box {
  width: 100%;
  height: 290px;
  padding-left: 20px;
  padding-right: 20px;
  background: radial-gradient(188.14% 174.71% at 32.93% 16.74%, #3182FF 0%, #C6EF7E 100%);
}

.graphic {
  position: absolute;
  top: 80px;
  right: 0;
}


.content-box {
  width: 100%;
  background: #FFF;
  padding: 36px 20px 36px 20px;
}

.acc-box {
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--CF-Gray-200, #EFEFEF);
  background: var(--CF-Gray-160, #F5F5F5);
}

.method-token {
  display: flex;
  height: 40px;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 999px;
  background: #fff;
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  border: 1px solid #DFDFDF;
}

.method-token.active {
  background: #111;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  border: none;
}

.content-title {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 162.5% */
}

.content-label-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.content-label-number {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background: #111;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.content-label {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.content-description {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.exclamation-mark-2 {
  width: 18px;
  height: 18px;
  border-radius: 999px;
  font-family: NanumSquare, sans-serif;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.exclamation-text-2 {
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.exclamation-underbar {
  width: 100%;
  height: 10px;
  background: #B4FB39;
}

.list-descriptions > li {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  text-indent: -10px;
}


.list-descriptions > li:before {
  content: "- ";
}

.image-placeholder {
  aspect-ratio: 1/1;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F5F5F5;
}

.list-descriptions-emp {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.question-wrapper {
  display: flex;
  gap: 4px;
  align-items: start;
}

.qmark {
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.question {
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 4px;
}

.answer-wrapper {
  display: flex;
  gap: 4px;
  align-items: start;
}

.amark {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.answer {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.next-collection-block {
  width: 100%;
  height: 32px;
  padding: 5px 20px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: var(--CF-Primary, #3182FF);
}
</style>