<script setup>

import {computed, nextTick, ref, watch} from "vue";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import {dateParser, timeParser} from "../../utils/coffee.js";
import CustomCheck from "@/components/coffee/CustomCheck.vue";
import CoffeeBottomSheet from "@/components/coffee/CoffeeBottomSheet.vue";
import axiosServices from "@/utils/axios/axios.js";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import {useRouter} from "vue-router";


const emits = defineEmits(['goPrev'])

const props = defineProps({
  availableDataValue: {
    type: Object || null,
    default: null,
    required: false
  },
  bagCountValue: {
    type: Number,
    default: 0,
    required: false
  },
  etcBagValue: {
    type: String,
    default: '',
    required: false
  },
  etcRequestValue: {
    type: String,
    default: '',
    required: false
  }
})

const goPrev = () => {
  emits('goPrev')
}


const alertIsShown = ref(false)
const alertBody = ref('')
const agree = ref(false)
const weightAgree = ref(false)
const registerResult = ref(null)

const agreeAll = computed(() => {
  return weightAgree.value && agree.value
})
const showAlert = (body) => {
  alertBody.value = body
  alertIsShown.value = true
}

const closeAlert = () => {
  alertIsShown.value = false
}

const showSuccessSheet = ref(false)
const coffeeStore = useCoffeeStore()

const register = async () => {
  try {
    coffeeStore.setLoading(true)
    const data = {
      dispose_date: props.availableDataValue.region.next_collection.date,
      bag_count: props.bagCountValue,
      etc_bag: props.etcBagValue.length === 0 ? null : props.etcBagValue,
      etc_request: props.etcRequestValue.length === 0 ? null : props.etcRequestValue,
    }
    const apiUrl = import.meta.env.VITE_BACKEND_HOST
    let config = {
      method: 'post',
      url: `${apiUrl}coffee/app/v1/dispose/request/`,
      data: data
    };
    const response = await axiosServices(config)
    registerResult.value = response.data
    console.log('registerResult.value')
    console.log(registerResult.value)
    await nextTick(() => {

    showSuccessSheet.value = true
    coffeeStore.setLoading(false)
    })
  } catch (e) {
    coffeeStore.setLoading(false)
    if (e.name === 'AxiosError') {
      let errors = e.response.data.errors
      let errorString = errors.reduce((acc, cur) => {
        return acc + `${cur.detail}\n`
      }, '')
      showAlert(errorString)
    } else {
      showAlert(e.message)
    }
  }
}

const router = useRouter()

const goToDetail = async () => {
  let id = registerResult.value.id
  await router.replace({name: 'coffee-collection-detail', params: {id: id}})
}
</script>

<template>
  <div>
    <div class="page-title mb-2">수거 신청 내역 확인</div>
    <div class="page-sub-title mb-12">아래 정보를 확인해 주세요.</div>
    <!--정보 컨테이너 시작-->
    <div class="info-container mb-6">
      <div class="container-header mb-2">수거 예정일</div>
      <div>
        <div class="mb-2">
          <div class="item-body">
            {{ dateParser(props.availableDataValue.region.next_collection.date) }}
            {{ timeParser(props.availableDataValue.region.collection_start_time) }} ~
            {{ timeParser(props.availableDataValue.region.collection_end_time) }}
          </div>
        </div>
        <div class="flex gap-x-[4px] align-start mb-2">
          <div class="exclamation-mark mt-1">
            <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
                  fill="white"/>
            </svg>
          </div>
          <div class="exclamation-text">매장 방문 배출 업체는 영업시간 내 수거 기사가 미방문시, 매장 앞에 배출 부탁드립니다.</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="container-header mb-2">업체 정보 및 배출 방법</div>
      <div class="mb-9">
        <div class="item-header">주소</div>
        <div class="item-body">{{ props.availableDataValue.address }}
          {{ props.availableDataValue.address_detail }}
        </div>
      </div>
      <div>
        <div class="item-header">배출 방법</div>
        <div class="item-body mb-2">{{ props.availableDataValue.address_dispose }}</div>
        <img :src="props.availableDataValue.dispose_photo.file" alt="배출 위치 사진" class="item-image mb-2"></img>

        <div class="flex gap-x-[4px] align-start mb-2">
          <div class="exclamation-mark mt-1">
            <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
                  fill="white"/>
            </svg>
          </div>
          <div class="exclamation-text">
            업체 주소 및 배출 장소 변경이 필요할 경우 고객센터(1660-3114)로 문의 부탁드립니다.
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="container-header mb-2">배출량</div>
      <div class="mb-9">
        <div class="item-header">수거 가방</div>
        <div class="item-body">{{ props.bagCountValue }}</div>
      </div>
      <div class="mb-9">
        <div class="item-header">기타 배출 용기</div>
        <div class="item-body">{{ props.etcBagValue ? props.etcBagValue : '없음' }}</div>
      </div>
      <div>
        <div class="item-header">기타 요청 사항</div>
        <div class="item-body">{{ props.etcRequestValue ? props.etcRequestValue : '없음' }}</div>
      </div>
      <div class="divider"></div>
      <div class="container-header mb-2">야간(오후 6시 이후) 수거 알림 수신 여부</div>
      <div>
        <div class="item-body">{{ props.availableDataValue.night_agree ? '동의' : '미동의' }}</div>
      </div>
    </div>
    <!--정보 컨테이너 끝-->
    <div class="mb-9">
      <div class="mb-3">
        <div class="pl-6 mb-1">
          <div class="cf-balloon-body cf-title-6 text-white">
            수거 운행을 줄여 탄소 배출을 함께 막아요!
          </div>
          <div class="cf-balloon-arrow ml-3"></div>
        </div>
        <CustomCheck :is-checked="weightAgree" :label="'배출량은 5kg 이상입니다.'" @update:modelValue="value => weightAgree = value">
          배출량이 5kg 이상입니다.
        </CustomCheck>
      </div>
      <div>
        <CustomCheck :is-checked="agree" :label="'정보 활용 및 제공 동의(필수)'" @update:modelValue="value => agree = value">
          수거 신청 정보를 확인했으며, 해당 정보로 수거 신청을 진행하겠습니다.
        </CustomCheck>
      </div>
    </div>

    <!-- 버튼 영역 시작 -->
    <div class="flex w-full justify-space-between gap-x-[8px]">
      <button class="white-btn max-w-[88px]" @click="goPrev">이전</button>
      <div class="w-full">
        <button :disabled="!agreeAll" class="primary-btn w-full" @click="register">수거 신청 완료</button>
      </div>
    </div>
    <!-- 버튼 영역 끝 -->
    <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="closeAlert"/>
  </div>
  <CoffeeBottomSheet :show="showSuccessSheet" @close="goToDetail">
    <template v-slot:title>수거 신청 완료</template>
    <template v-slot:body>
      <div class="page-sub-title mb-6">
        수거 기사님이 <span style="font-weight: 800">{{ dateParser(registerResult.dispose_date) }}
        {{ timeParser(registerResult.region.collection_start_time) }} 부터
        {{ timeParser(registerResult.region.collection_end_time) }} 사이</span> 방문하여 커피찌꺼기를 수거할 예정입니다.
      </div>
      <div class="flex gap-x-[4px] align-start mb-2">
        <div class="exclamation-mark mt-1">
          <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
                fill="white"/>
          </svg>
        </div>
        <div class="exclamation-text">다시 한번 확인해주세요.</div>
      </div>
      <div class="sheet-sub-title mb-3">
        매장 방문 수거 시 아래의 경우 매장 앞에 수거 가방을 미리 배출해 주세요.
      </div>
      <ul class="pb-2">
        <li>{{ dateParser(registerResult.dispose_date) }} 영업시간이
          {{ timeParser(registerResult.region.collection_start_time) }} 이후 시작하는 경우, 전날 폐점 시 미리 배출
        </li>
        <li>{{ dateParser(registerResult.dispose_date) }} 영업시간 중 수거가 이루어지지 않은 경우, 폐점 시 미리 배출</li>
      </ul>
    </template>
  </CoffeeBottomSheet>
</template>

<style scoped>

.container-header {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.page-sub-title {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.info-container {
  width: 100%;
  padding: 32px 16px;
  border-radius: 20px;
  border: 1px solid #DFDFDF;
}

.item-header {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  margin-bottom: 8px;
}

.item-body {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.item-image {
  border-radius: 8px;
}

.sheet-sub-title {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.divider {
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #EFEFEF;
}
</style>